<template>
  <div v-if="data">
    <a-form v-bind="data.config" :form="form" id="table">
      <template v-for="item in data.list">
        <template v-if="item.type === 'table'">
          <table class="mytb" :key="item.key">
            <tr class="thtt" v-for="(tr, trIdx) in item.trs" :key="trIdx">
              <template v-for="(td, tdIdx) in tr.tds">
                <td :colspan="td.colspan" v-if="td.colspan > 0" :key="tdIdx">
                  <template v-for="ele in td.list">
                    <div :style="ele.options" :key="ele.key" v-if="ele.type === 'text'">
                      {{ ele.label }}
                    </div>
                    <a-form-item :key="ele.key" v-if="ele.type === 'input'">
                      <a-input
                        :maxLength="ele.options.maxLength"
                        v-decorator="['documentNumber', { rules: ele.options.rules }]"
                        :placeholder="ele.options.placeholder"
                      />
                    </a-form-item>
                  </template>
                </td>
              </template>
            </tr>
            <template>
              <!-- <tr v-for="(o, i) in list" :key="i" class="bigtt align_l">
                <th>{{ o.name }}</th>
                <td colspan="3" class="text-a-l">
                  <a-tag color="#108ee9" v-for="tag in o.val" :key="tag.id">
                    <OpenData :type="tag.type" :openid="tag.userId || tag.id" :corpid="tag.corpId"></OpenData>
                  </a-tag>
                </td>
              </tr> -->
            </template>
          </table>
        </template>
      </template>
    </a-form>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      form: this.$form.createForm(this)
    }
  }
}
</script>

<style lang="less" scoped>
.mytb {
  width: 100%;
  border: 1px solid black;
  font-size: 14px;
  table-layout: fixed;

  .upload_file {
    cursor: pointer;
    // margin-right: 8px;
  }

  th,
  td {
    text-align: center;
    border: 1px solid black;
  }

  .thtt {
    height: 50px;
  }

  .tdtt {
    height: 50px;
  }

  .bigtt {
    height: 150px;

    td {
      white-space: pre-line;
    }
  }

  .align_l {
    td {
      text-align: left;
      padding: 10px;
      vertical-align: text-top;
      white-space: pre-line;
    }
  }
}</style>
